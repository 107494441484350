@import url('https://fonts.googleapis.com/css?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

.home {
    width: auto;  /* 100% */
    height: auto; /* 90vh */
    display: flex;
    justify-content: top;
    align-items: left;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
    /* font-family: Verdana, Arial, Tahoma, Serif; */
    /* font-family: "Just Another Hand";*/
    /* font-family: "Anton", "Oswald"; */
    /* font-family: "Berkshire Swash"; */
    /* font-family: "Gill Sans", sans-serif; */
  }

  .headerContainer {
    width: auto;
    margin-top: 50px;
    margin-left: 50px;
  }

  .headerContainer img {
    margin-top: 50px;
    margin-left: 50px;
  }

  .headerContainer h1 {
    justify-content: center;
    align-items: center;
    /* font-family: "Just Another Hand"; */
    /* font-family: "Anton"; */
    /* font-size: 120px; */
    /* height: 60px;*/
    /* font-weight: 50;*/
    /*color: black;*/
  }
  
  .headerContainer p {
    padding: 0;
    /* font-size: 50px; */
    /* font-family: "Anton"; */
    /* font-weight: lighter; */
    /* color: rgb(68, 26, 219); */
  }

  .headerContainer button {
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    /*font-family: "Just Another Hand"; */
    font-family: "Anton";
    background-color: #101418;
    height: 50px;
    width: 180px;
    color: white;
    text-decoration: none;
    font-size: 30px;
  }
  
  .headerContainer button:hover {
    background-color: #364750;
    cursor: pointer;
    transition: 0.3s ease-in;
  }
  
  .headerContainer h2 {
    justify-content: center;
    align-items: center;
    padding: 0;
    font-family: "Oswald";
    /* font-family: "Just Another Hand"; */
    font-size: 32px;
    height: 120px;
    font-weight: bolder;
    color: black;
  }


.product-title h3 {
  padding: 0;
  font-size: 24px;
  font-family: "Oswald";
  text-align: center;
  font-weight: bolder;
  color: black;
}

.headerContainer img {
  width: 30%;
  margin-bottom: 350px;
}
  
  @media only screen and (max-width: 650px) {
    .home {
      justify-content: center;
      align-items: center;
    }
  
    .headerContainer {
      margin-left: 0px;
      border-radius: 10px;
      padding: 10px;
      width: 90%;
      height: auto; /* 90vh; */
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      background-color: #121619;
      color: white;
    }

    .headerContainer img {
      margin-top: 50px;
      margin-left: 0px;
    }  

    .headerContainer h1 {
      margin-top: 10px;
      margin-bottom: 30px;
      text-align: center;
      line-height: 35px;
      /* font-size: 70px; */
      /* height: 30px; */
      color: white;
    }

    .headerContainer h2 {
      justify-content: center;
      align-items: center;
      /* font-family: "Just Another Hand"; */
      font-family: "Oswald";
      font-size: 20px;
      height: 40px;
      line-height: 30px;
      font-weight: 50;
      color: white;
    }

    .headerContainer p {
      /* font-size: 30px; */
      /*height: 80px; */
      /*font-family: "Anton"; */
      line-height: 25px;
      justify-content: center;
      color: white;
      text-align: center;
    }

    .headerContainer hr {
      height: 2;
      color: white;
      background: white;
      border-color: white;
    }    

    .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }
    
    .headerContainer img {
      width: 100%;
      margin-bottom: 50px;
      align-items: center;
    }
  }

.home-body {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
  /* font-family: Verdana, Arial, Tahoma, Serif; */
  /* font-family: "Just Another Hand"; */
  /* font-family: "Anton"; */
  /* font-family: "Gill Sans", sans-serif; */
}


  .bodyContainer {
    width: auto;
    margin-left: 50px;
  }

  .playerDiv {
    width:100%;
    position: relative;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .header__section {
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 40px;
    background-color: rgb(70, 70, 172);
  }
  
  .header__text {
    color: white;
    font-size: 28px;
    font-weight: 800;
    font-family: Georgia, 'Times New Roman', Times, serif;
    padding-top: 5px;
    padding-bottom: 5px;
  }
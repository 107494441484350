.navbarItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 30px;
  background-color: #222;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 90%;
  height: 80px;
  border-radius: 13px;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
}

.fa-tc {
  margin-left: 0.2rem;
  font-size: 1.6rem;
}

.nav-menu{
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.nav-links{
  text-decoration: none;
  color: #fff;
  padding: 0.7rem 1rem;
  white-space: nowrap;
}

.nav-links i{
  padding-right: 10px;
}

.nav-links:hover{
  background: rgb(80, 80, 238);
  color: #fff;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.fa-bars, .fa-times{
  color: #fff;
}

.menu-icons {
  display: none;
}

.nav-links-mobile{
  padding: 0.5rem 1rem;
  white-space: nowrap;
  background-color: #fafafa;
  border-radius: 3px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: #222;
}


@media screen and (max-width: 850px) {
  .navbarItems{
    z-index: 99;
  }

  .nav-menu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: rgba(43, 84, 126, 0.75);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -110%;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  .nav-menu.active{
    left: 0%;
  }

  .nav-links{
    display: block;
    widows: 100%;
    padding: 2rem 0;
    color: #fff;
  }

  .nav-links:hover{
    background: rgba(72, 99, 160, 0.5);
    backdrop-filter: blur(20px);
  }

  .menu-icons {
    display: block;
  }

  .nav-links-mobile{
    padding: 1.5rem;
    display: block;
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    width: 80%;
    margin: auto;
  }
  
}

.navbar {
    width: 100%;
    height: 60px;
    background-color: #232b30;
    display: flex;
    flex-direction: row;
  }
  
  .navbar .leftSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 150px;
  }
  
  .navbar .leftSide img {
    width: 150px;
  }
  
  .navbar .rightSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
  }
  
  .navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .navbar .rightSide svg {
    font-size: 40px;
  }
  
  .navbar #open {
    padding-left: 0px;
  }
  .navbar #open img {
    display: none;
  }
  
  .navbar #close img {
    display: inherit;
  }
  
  .navbar #open .hiddenLinks {
    display: inherit;
    margin-left: 30px;
  }
  
  .navbar #close .hiddenLinks {
    display: none;
  }
  
  .navbar #open a {
    width: 70px;
    margin: 5px;
  }
  @media only screen and (max-width: 900px) {
    .navbar .rightSide a {
      width: 50px;
    }
    .navbar .leftSide {
      padding-left: 50px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .navbar .rightSide a {
      display: none;
    }
    .navbar .rightSide {
      justify-content: flex-end;
      padding-right: 20px;
    }
  
    .navbar .rightSide button {
      display: inherit;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .navbar .rightSide button {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  }
  
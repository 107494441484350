.customer-page {
    background-color: rgb(238, 238, 238);
    height: auto;
    max-width: 100%;
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 20px;
    //display: flex;
    justify-content: center;
    align-items: center;
  }

.center {
display: flex;
justify-content: center;
align-items: center;
//height: 200px;
//border: 3px solid green;
}

.form-inline {
    margin: 0 auto;
    display: table;
    justify-content: center;
    align-items: center;
}

.form-box {
    background-color: white;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 15px -5px black;
  }

  .file-card {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #116466;
    background-color: #d1e8e2;
    min-width: 800px;
    min-height: 230px;

    .file-inputs {
        position: relative;
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
            height: 26px;
            max-width: 200px;
        }

        button {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            background-color: #f55e30;
            font-size: 1.1rem;
            cursor: pointer;
            border-radius: 4px;
            border: none;
            outline: none;

            transition: background-color 0.4s;
            box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

            i {
                width: 1.5em;
                height: 1.5em;
                padding: 0.4em;
                background-color: #fff;
                color: #f55e30;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }

        &:hover {
            button {
                background-color: #f15120;
            }
        }
    }
}

.file-card2 {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #bab2b5;
    background-color: #eee2dc;
    min-width: 800px;
    min-height: 140px;

    .centered-div {
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
    }

    .file-inputs {
        position: relative;
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
            height: 26px;
            max-width: 200px;
        }

        button {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            background-color: #f55e30;
            font-size: 1.1rem;
            cursor: pointer;
            border-radius: 4px;
            border: none;
            outline: none;

            transition: background-color 0.4s;
            box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

            i {
                width: 1.5em;
                height: 1.5em;
                padding: 0.4em;
                background-color: #fff;
                color: #f55e30;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }

        &:hover {
            button {
                background-color: #f15120;
            }
        }
    }
}

.file-card3 {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #374785;
    background-color: #a8d0e6;
    min-width: 800px;
    min-height: 140px;

    .centered-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .file-inputs {
        position: relative;
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
            height: 26px;
            max-width: 200px;
        }

        button {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            background-color: #f55e30;
            font-size: 1.1rem;
            cursor: pointer;
            border-radius: 4px;
            border: none;
            outline: none;

            transition: background-color 0.4s;
            box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

            i {
                width: 1.5em;
                height: 1.5em;
                padding: 0.4em;
                background-color: #fff;
                color: #f55e30;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }

        &:hover {
            button {
                background-color: #f15120;
            }
        }
    }
}

.file-card4 {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #4a5809;
    background-color: #b1bb85;
    min-width: 800px;
    min-height: 140px;

    .centered-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .file-inputs {
        position: relative;
        margin-top: 1.5em;
        margin-bottom: 1.5em;

        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
            height: 26px;
            max-width: 200px;
        }

        button {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            background-color: #f55e30;
            font-size: 1.1rem;
            cursor: pointer;
            border-radius: 4px;
            border: none;
            outline: none;

            transition: background-color 0.4s;
            box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

            i {
                width: 1.5em;
                height: 1.5em;
                padding: 0.4em;
                background-color: #fff;
                color: #f55e30;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }

        &:hover {
            button {
                background-color: #f15120;
            }
        }
    }
}

.main {
    font-weight: normal;
    margin-bottom: 0.4em;
    font-family: Arial, Helvetica, sans-serif;
}

.info {
    font-weight: normal;
    font-size: 0.8rem;
    margin-bottom: 0.4em;
}

/* .carousel .thumb img {
    width: 100%;
    height: 100%;
}

.carousel .slide .legend {
    width: 50%;
    justify-content: center;
  }

.carousel .carousel-slider {
    width: 50%;
    justify-content: center;
}

.carousel .slide img {
    max-height: auto;
    max-width: 400px;
} */
